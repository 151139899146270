import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import axios from "axios";
import "../styles/Feed.css";

import StorySlider from "./StorySlider";

export default function Feed({ userName }) {
  const [isLoading, setIsLoading] = useState(false);
  const [stories, setStories] = useState([]);
  const [feedData, setFeedData] = useState([]);
  const [feedPagination, setFeedPagination] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);
  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const { data } = await axios.get(
          `https://insta-server.gferguson954.now.sh/api/v1/feed/${userName}`
        );
        formatUserData(data);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const formatUserData = (storyData) => {
    console.log(storyData, 'story data')
    try {
      // let parser = new DOMParser();

      // let storyDoc = parser.parseFromString(storyData, "text/html");

      // console.log(storyDoc, 'storie')
      // let privateUser = Boolean(storyDoc.querySelector(".title"));
      // setIsPrivate(privateUser);

      // let storiesInfo = Array.from(storyDoc.querySelectorAll(".story"));
      // storiesInfo = storiesInfo.map(story => story.innerHTML);

      if (storyData.length) {
        setStories(storyData);
      }

      // let feedPaginationInfo = feedDoc.querySelector(".showMore").innerHTML;
      // if (feedPaginationInfo) {
      //   setFeedPagination(feedPaginationInfo);
      // }

      // let feedDataInfo = Array.from(feedDoc.querySelectorAll(".pptabdiv"));

      // feedDataInfo = feedDataInfo.map(feed => feed.innerHTML);
      // if (feedDataInfo.length) {
      //   setFeedData(feedDataInfo);
      // }
    } catch (error) { }
  };

  const renderFeedData = () => {
    // console.log(feedPagination, "pagination");
    const validPosts = feedData.filter((feed, i) => {
      try {
        let parser = new DOMParser();
        let doc = parser.parseFromString(feed, "text/html");
        const media = doc.getElementsByTagName("img")[0].getAttribute("src");
        const likes = doc.querySelector(".photolike").textContent;
        const comments = doc.querySelector(".photocomment").textContent;

        return true;
      } catch (error) {
        return false;
      }
    });

    return validPosts.map((post, i) => {
      let parser = new DOMParser();
      let doc = parser.parseFromString(post, "text/html");
      let media = doc.getElementsByTagName("img")[0].getAttribute("src");
      if (!media) {
        media = doc.getElementsByTagName("img")[0].getAttribute("data-src");
      }
      const likes = doc.querySelector(".photolike").textContent;
      const comments = doc.querySelector(".photocomment").textContent;
      return (
        <div
          key={i}
          className="post-wrapper col-xs-6 col-sm-6 col-md-6 col-lg-4"
        >
          <img src={media} alt="post preview" />
          <div className="post-meta">
            <p>{likes}</p>
            <p>{comments}</p>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="feed-wrapper container-fluid">
      {isLoading && (
        <div className="spinner-wrapper">
          <Loader type="Oval" color="#e1306c" height="70" width="70" />
        </div>
      )}
      {!isLoading && stories && <StorySlider stories={stories} />}
      <div className="feed-content row">
        {!isLoading && isPrivate && <p className="private-account">This Account is Private</p>}
        {/* {!isLoading &&
          !isPrivate &&
          feedData &&
          feedData.length &&
          renderFeedData()} */}
      </div>
    </div>
  );
}
