import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";

import "../styles/Nav.css";

export default function Nav({ onSubmit }) {
  const debounceSubmit = _.debounce(onSubmit, 500);

  return (
    <header className="nav-wrapper">
      <Link to="/" aria-label="Home">
        <input
          type="text"
          aria-label="Enter Instagram Username here"
          placeholder="Enter Instagram Username Here"
          autoFocus={true}
          onChange={e => debounceSubmit(e.target.value)}
        />
        <i className="fa fa-search" />
      </Link>
    </header>
  );
}
