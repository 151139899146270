import React from "react";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import verified from "../images/verified.png";
import "../styles/Users.css";

export default function Users({ isLoading, users }) {
  const renderUsers = () => {
    if (users && !users.length) {
      return <p className="user-warning">No users found with that username.</p>;
    } else if (users && users.length) {
      return users.map((user, i) => {
        if (user) {
          return (
            <Link
              key={i}
              to={{
                pathname: `/user/${user.userName}`,
                state: { user }
              }}
              className="user-link"
            >
              <div className="profile-preview-wrapper">
                <img
                  src={user.profileImage}
                  className="profile-pic-preview"
                  alt="user profile picture"
                />
                <div className="profile-content">
                  <div className="user-box">
                    <p className="username">{user.userName}</p>
                    {user.isVerified && (
                      <img src={verified} alt="verified badge" />
                    )}
                  </div>
                  <p className="bio">{user.bio}</p>
                </div>
              </div>
            </Link>
          );
        }
      });
    }
  };
  return (
    <div>
      {isLoading ? (
        <div className="spinner-wrapper">
          <Loader type="Oval" color="#e1306c" height="70" width="70" />
        </div>
      ) : (
        renderUsers()
      )}
      <article>
        <h1>
          Welcome to InstaViewr! Download full size Instagram profile pictures and
          stories.
        </h1>
        <h5>
          Simply search by username in the top search bar and select what
          Instagram user you want to see. InstaViewr allows for anonymous
          browsing so feel free to search away!
        </h5>
      </article>
    </div>
  );
}
