import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import { Redirect } from 'react-router-dom';
import axios from "axios";
import verified from "../images/verified.png";
import "../styles/Profile.css";

import ProfilePic from "./ProfilePic";
import Feed from "./Feed";

export default function Profile(props) {
  const { match, history } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [selectedTab, setSelectedTab] = useState("profile");
  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const { data } = await axios.get(
          `https://insta-server.gferguson954.now.sh/api/v1/user/${match.params.username}`
        );
        formatUserData(data);
      } catch (error) {
        return <Redirect to='/' />
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const formatUserData = async ({ profileData }) => {
    try {
      let userData = {
        profilePic: profileData.profile,
        userName: profileData.username,
        isVerified: Boolean(profileData.is_verified),
        followers: profileData.followers,
        profileLink: `https://www.instagram.com/${profileData.username}/`,
        stories: null,
        feedData: null,
        feedPagination: null
      };

      setUserData(userData);
    } catch (error) { }
    // let userId;
    // data.info.forEach(info => {
    //   let result = info.match(/\BprofilePage_|profilePage_\B/);
    //   if (result) {
    //     console.log(result, "result");
    //     userId = result.input.substr(result.index, 50);
    //     console.log(userId, "user id");
    //     userId = userId.match(/\d+/g).map(Number)[0];
    //   }
    // });

    // if (userId) {
    //   console.log(userId);
    //   try {
    //     const {
    //       data: { user }
    //     } = await axios.get(
    //       `https://i.instagram.com/api/v1/users/${userId}/info/`
    //     );
    //     console.log(user, "info");
    //     setUserData(user);
    //   } catch (err) {}
    // }
  };

  const setTab = (tab) => {
    setSelectedTab(tab);
  }

  const renderTabChoice = ({ profilePic, userName }) => {
    console.log(selectedTab, 'selected tab')
    switch (selectedTab) {
      case "profile":
        return <ProfilePic profilePic={profilePic} userName={userName} />;
      case "feed":
        return <Feed userName={match.params.username} />;
      default:
        return <ProfilePic profilePic={profilePic} userName={userName} />;
    }
  };

  const renderUserData = () => {
    const {
      followers,
      isVerified,
      profileLink,
      profilePic,
      userName
    } = userData;

    return (
      <div>
        <div className="user-overview-wrapper">
          <div>
            <img
              className="profile-pic-preview"
              src={profilePic}
              alt="user profile picture"
            />
            <div className="name-info">
              <p className="username">{userName}</p>
              {isVerified && (
                <img src={verified} className="verified" alt="verified badge" />
              )}
            </div>
            {/* <p className="bio">{userNa}</p> */}
          </div>
          <div className="user-stats">
            <p>
              <span>{followers}</span>
            </p>

            <a
              href={`https://www.instagram.com/${userName}/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit Profile on Instagram
            </a>
          </div>
        </div>
        <div className="choice-wrapper">
          <button
            className={selectedTab === "profile" ? "selected" : "tab-option"}
            onClick={() => setTab('profile')}
          >
            Profile Picture <i className="fa fa-user" />
          </button>
          {/* <button
            className={selectedTab === "feed" ? "selected" : "tab-option"}
            onClick={() => setTab('feed')}
          >
            Stories <i className="fa fa-instagram" />
          </button> */}
        </div>
        {renderTabChoice(userData)}
      </div>
    );
  };

  return (
    <div>
      {isLoading && (
        <div className="spinner-wrapper">
          <Loader type="Oval" color="#e1306c" height="70" width="70" />
        </div>
      )}
      {userData && renderUserData()}
    </div>
  );
}
