import React from "react";
import "../styles/Footer.css";

export default function Footer() {
  return (
    <div className="footer-wrapper">
      <footer>
        <p>&copy;{new Date().getUTCFullYear()} InstaViewr</p>
        <p>
          This site gets photos and videos from the Instagram cdn servers. All
          photos and videos belong to Instagram
        </p>
      </footer>
    </div>
  );
}
