import React from "react";
import "../styles/Profile.css";

export default function ProfilePic({ profilePic, userName }) {
  return (
    <div>
      <div className="image-wrapper">
        <img src={profilePic} alt="Large profile picture" />
      </div>
      <a
        className="download-button-main"
        href={profilePic}
        download={`${userName}-profile-pic`}
      >
        <button>
          Download
          <i className="fa fa-download" />
        </button>
      </a>
    </div>
  );
}
