import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import ReactGA from 'react-ga';
import axios from "axios";
import "./App.css";

import Nav from "./components/Nav";
import Users from "./components/Users";
import Profile from "./components/Profile";
import ProfileFeed from "./components/ProfileFeed";
import Footer from "./components/Footer";

export default function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    ReactGA.initialize('UA-108879520-4');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const handleSearchChange = async searchValue => {
    if (!searchValue) {
      return;
    }

    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `https://insta-server.gferguson954.now.sh/api/v1/users/${searchValue.trim()}`
      );

      const userData = data.users.map(account => {
        return {
          userName: account.user.username,
          profileImage: account.user.profile_pic_url,
          bio: account.user.full_name,
          isVerified: account.user.is_verified
        }
      })
      // let parser = new DOMParser();
      // let doc = parser.parseFromString(data, "text/html");
      // let userData = Array.from(doc.querySelectorAll(".searchUsers a"));
      // userData = userData.map(user => {
      //   const userInfo = user.innerHTML;
      //   let userParser = new DOMParser();
      //   let userDoc = userParser.parseFromString(userInfo, "text/html");
      //   let userName = userDoc.querySelector(".usrName").textContent;
      //   userName = userName.substring(1);
      //   return {
      //     userName,
      //     profileImage: userDoc
      //       .getElementsByTagName("img")[0]
      //       .getAttribute("src"),
      //     bio: userDoc.querySelector(".usrFullName").textContent
      //   };
      // });


      setUsers(userData);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="App">
      <Nav onSubmit={handleSearchChange} />
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Users isLoading={isLoading} users={users} />}
        />
        <Route exact path="/user/:username" component={Profile} />
        <Route exact path="/feed/:username" component={ProfileFeed} />
        <Route
          path="/"
          render={() => <Users isLoading={isLoading} users={users} />}
        />
      </Switch>
      <Footer />
    </div>
  );
}
