import React from "react";
import "../styles/Feed.css";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

export default function StorySlider({ stories }) {
  const displayMedia = ({ download, media, mediaType }) => {
    return mediaType === "video" ? (
      <div className="story-wrapper">
        <video controls>
          <source src={media} />
        </video>
        <a href={download} download className="btn">
          Download Story
          <i className="fa fa-download" />
        </a>
      </div>
    ) : (
      <div className="story-wrapper">
        <img src={media} alt="story photo" />
        <a href={download} download className="btn">
          Download Story
          <i className="fa fa-download" />
        </a>
      </div>
    );
  };

  const renderSlides = () => {
    return stories.map((story, i) => {
      let parser = new DOMParser();
      let doc = parser.parseFromString(story, "text/html");
      let slideData = {
        download: null,
        media: null,
        mediaType: null
      };

      slideData.download = doc
        .getElementsByTagName("a")[0]
        .getAttribute("href");
      if (Boolean(doc.getElementsByTagName("source")[0])) {
        slideData.media = doc
          .getElementsByTagName("source")[0]
          .getAttribute("src");
        slideData.mediaType = "video";
      } else {
        slideData.media = doc
          .getElementsByTagName("img")[0]
          .getAttribute("src");
        slideData.mediaType = "image";
      }
      return (
        <Slide index={i} key={i} className="story-slide">
          {displayMedia(slideData)}
        </Slide>
      );
    });
  };

  const renderStories = () => {
    if (!stories.length) {
      return <p>No stories by this user today</p>;
    }
    const slidesToShow = window.innerWidth < 768 ? 2 : 3;
    return (
      <CarouselProvider
        naturalSlideWidth={250}
        naturalSlideHeight={445}
        totalSlides={stories.length}
        visibleSlides={slidesToShow}
        // isPlaying={true}
        // interval={5000}
      >
        <Slider>{renderSlides()}</Slider>
        <ButtonBack id="carousel-back">Back</ButtonBack>
        <ButtonNext id="carousel-next">Next</ButtonNext>
      </CarouselProvider>
    );
  };

  return <div>{renderStories()}</div>;
}
